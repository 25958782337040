
.div-container {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    flex-direction: column;
    align-items: center;
}

.tech {
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.left {
    width: 50%;
    overflow: hidden;
}

.left img {
    /* padding: 2rem; */
    height: 80vh;
}

.right {
    /* background-color: aqua; */
    width: 50%;
    padding-left: 2rem;
    padding: 3rem;
}

.right h3 {
    font-size: 3rem;
}

.location {
    padding: 2rem 0;
}

.location p {
    line-height: 1.5rem;
    font-size: 1.2rem;
}

.contacts {
    /* padding: 1rem 0; */
    line-height: 1.5rem;
    font-size: 1.2rem;
}

.working-hours {
    display: flex;
    padding: 2rem 0;
}

.working-hours div {
    width: 50%;
}

.business-hours div {
    padding: 0.5rem 0;
}

.business-hours div p {
    line-height: 1.5rem;
    font-weight: 200;
}

.socials {
    display: flex;
    flex-direction: row;
}

.socials p {
    margin-right: 2rem;
    margin-top: 1rem;
    font-size: 1.2rem;
    color: gray;
}

.logo img {
    margin-top: 3rem;
    height: 12vh;
}

.app {
    display: flex;
    margin: 1rem 0;
}

@media only screen and (max-width: 600px) {
    
    .div-container {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .tech {
        width: 90%;
        display: flex;
        flex-direction: column-reverse;
    }

    .left {
        width: 100%;
        overflow: hidden;
    }

    .left img {
        padding: 1rem;
        height: 40vh;
    }

    .right {
        /* background-color: aqua; */
        width: 100%;
        padding-left: 2rem;
        padding: 1rem;
    }

    .right h3 {
        font-size: 1.5rem;
    }

    .location {
        padding: 2rem 0;
    }

    .location p {
        line-height: 1.5rem;
        font-size: 1rem;
    }

    .contacts {
        /* padding: 1rem 0; */
        line-height: 1.5rem;
        font-size: 1rem;
    }

    .working-hours {
        display: flex;
        padding: 2rem 0;
    }

    .working-hours div {
        width: 100%;
    }

    .business-hours div {
        padding: 0.5rem 0;
    }

    .business-hours div p {
        line-height: 1.5rem;
        font-weight: 200;
    }

    .socials {
        display: flex;
        flex-direction: row;
    }

    .socials p {
        margin-right: 2rem;
        margin-top: 1rem;
        font-size: 1.2rem;
        color: gray;
    }

    .logo img {
        margin-top: 3rem;
        height: 10vh;
    }
}