.button {
    padding: 1.5rem 4rem;
    border: 0.1rem solid white;
    background: none;
    border-radius: 4rem;
    font-size: 1rem;
    background: white;
    cursor: pointer;
}

.button:hover {
    background-color: black;
    color: white;
}

@media only screen and (max-width: 600px) {
    .button {
        padding: 1rem 2rem;
        border: 0.1rem solid white;
        background: none;
        border-radius: 4rem;
        font-size: 1rem;
        background: white;
    }
}