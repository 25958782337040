.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120vh;
    background-color: #05415b;
    width: 100%;
}

.container div{
    padding: 2rem;
}

.inner-container {
    width: 90%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.content {
    text-align: justify;
    width: 60%;
    color: white;
    margin-top: 3rem;
}

.content p {
    /* margin: 1rem 0; */
}

.button {
    margin-top: -5rem;
    margin-left: -4rem;
}


.content h3 {
    font-size: 4rem;
    padding-bottom: 2rem;
}

.content p {
    padding-bottom: 2rem;
    line-height: 1.5rem;
}

.image {
    overflow: hidden;
    /* width: 50%; */
    /* padding: 2rem; */
    /* background-color: aqua; */
}

.image img {
    margin-top: 3rem;
    filter: grayscale();
    height: 70vh;
}

@media only screen and (max-width: 600px) {
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 180vh;
        background-color: #05415b;
        width: 100%;
    }
    
    .container div{
        padding: 2rem;
    }
    
    .inner-container {
        width: 150%;
        display: flex;
        /* align-items: center; */
        justify-content: center;
        flex-direction: column-reverse;
    }
    
    .content {
        text-align: justify;
        width: 100%;
        color: white;
        margin-top: -2rem;
    }
    
    .content h3 {
        font-size: 4rem;
        padding-bottom: 2rem;
    }
    
    .content p {
        padding-bottom: 2rem;
        line-height: 1.5rem;
        color: gray;
    }
    
    .image {
        overflow: hidden;
        /* width: 50%; */
        /* padding: 2rem; */
        /* background-color: aqua; */
    }
    
    .image img {
        margin-top: -2rem;
        filter: grayscale();
        height: 30vh;
    }
}