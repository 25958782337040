.container {
    display: flex;
    align-items: center;
    height: 100vh;
    /* background-image: url('../../assets/home.jpg'); */
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    background-position: center;
}

.slider {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    padding-left: 7rem;
    text-align: justify;
    width: 50%;
    color: white;
}

.content h3 {
    font-size: 4rem;
    padding-bottom: 2rem;
}

.content p {
    padding-bottom: 2rem;
    line-height: 1.5rem;
}

.heading2 {
    margin-left: 6rem;
    margin-top: 5rem;
    font-size: 2rem;
    font-weight: 500;
}

.div-container {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    height: 50vh;
}

.tech {
    width: 90%;
    display: flex;
    flex-direction: row;
}

.tech h3 {
    font-size: 1.5rem;
    font-weight: 500;
}

.left {
    /* background: red; */
    width: 50%;
    margin: 0 2rem;
    background-image: url('../../assets/HouseContainer1.jpg');
    background-size: cover;
}

.left p {
    margin-top: -6rem;
    line-height: 7rem;
}

.right {
    background-color: aqua;
    width: 50%;
    margin: 0 2rem;
    background-image: url('../../assets/Wooden1.jpg');
    background-size: cover;
}

.right p {
    margin-top: -6rem;
    line-height: 7rem;
}

@media only screen and (max-width: 600px) {
    .container {
        display: flex;
        align-items: center;
        height: 50vh;
        /* background-image: url('../../assets/home.jpg'); */
        background-size: cover;
    }
    
    .content {
        padding: 0 1rem;
        text-align: justify;
        width: 100%;
        color: white;
    }
    
    .content h3 {
        font-size: 2rem;
        padding-bottom: 2rem;
    }
    
    .content p {
        padding-bottom: 2rem;
        line-height: 1.5rem;
    }
    
    .heading2 {
        margin-left: 2rem;
        margin-top: 3rem;
        font-size: 1.5rem;
        font-weight: 500;
    }
    
    .div-container {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
    }
    
    .tech {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .tech h3 {
        font-size: 1.5rem;
        font-weight: 500;
    }
    
    .left {
        width: 80%;
        margin: 0 2rem;
        height: 40vh;
    }

    .left p {
        margin-top: -6rem;
        line-height: 7rem;
    }
    
    .right {
        background-color: aqua;
        width: 80%;
        margin: 0 2rem;
        height: 40vh;
        margin-top: 4rem;
    }

    .right p {
        margin-top: -6rem;
        line-height: 7rem;
    }
}
